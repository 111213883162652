import EmailForm from '../emailForm/emailForm';
import './contactus.scss';

function ContactUs() {
    return (
        <div id="contactUs">
          <EmailForm />
        </div>
      );
}

export default ContactUs;